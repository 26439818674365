<template>
  <div class="record">
    <div class="header">我的历史</div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-loading="loading" element-loading-text="请稍等..."  label="检索历史" name="first">
        <div v-if="his_list.length > 0" class="box">
          <div v-for="(item, index) in his_list" :key="item.id" class="sbox">
            <el-button
              @click="handelrDel(item.id, index)"
              class="el-icon-close fr emp"
              type="text"
            ></el-button>
            <div class="title">
              <span v-for="(val, i) in item.expCondtionList" :key="i">{{
                val
              }}</span>
            </div>
            <el-image fit="contain" v-if="item.imageUrl" :src="item.imageUrl"></el-image>
            <div class="footer">
              <p v-if="item.createTime">{{ item.createTime }}</p>
              <el-button
                @click="handlerJS(item.conditionInfo)"
                type="primary"
                class="again"
                >重新检索</el-button
              >
            </div>
          </div>
        </div>
        <div class="none_img" v-else>
          <el-image :src="require('@/assets/img/tab/none.png')"></el-image>
          <div class="none_text">暂无历史</div>
        </div>
      </el-tab-pane>
        <div class="clearfix">
          <el-pagination
            class="fr"
            hide-on-single-page
            :page-count="totalPages"
            @current-change="handlerPageChange"
          ></el-pagination>
        </div>
    </el-tabs>
  </div>
</template>

<script>
import { queryHistoryInfo, delHistoryInfo } from "@/api";
export default {
  data() {
    return {
      activeName: "first",
      his_list: [],
      totalPages: 0,
      pageSize: 10,
      pageNo: 1,
      loading: false
    };
  },
  mounted() {
    this.handlerHisSumbit();
  },
  methods: {
    // 历史记录查询
    handlerHisSumbit() {
      this.loading = true
      const params = {pageNo:this.pageNo,pageSize:this.pageSize}
      queryHistoryInfo(params).then((res) => {
        this.his_list = res.result.records;
        this.totalPages = res.result.pages;
        this.loading = false
      });
    },
    // 历史分页查询
    handlerPageChange(val) {
      this.his_list = [];
      this.pageNo = val;
      this.handlerHisSumbit()
    },
    // 检索
    handlerJS(params) {
      this.$router.push({ path: "/list", query: { params } });
    },
    // 删除历史记录
    handelrDel(id, index) {
      this.$confirm("确定删除该历史检索信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delHistoryInfo({ id }).then(() => {
          this.$message.success("操作成功");
          this.$delete(this.his_list, index);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  width: 80%;
  .header {
    width: 100px;
    padding: 42px 10px 16px;
    border-bottom: 2px solid #2a7efb;
    margin-bottom: 40px;
    margin-left: 15px;
    font-size: 22px;
  }
  .el-tabs {
    margin: 0 20px;
    .el-tab-pane{
      height: 600px;
      .box {
        width: 100%;
        height: 100%;
        overflow: auto;
        .sbox {
          box-sizing: border-box;
          width: 100%;
          height: 300px;
          border: 1px solid #ddd;
          position: relative;
          .title {
            margin: 20px;
            > span {
              display: inline-block;
              border: 1px solid #ddd;
              padding: 10px;
              margin-right: 15px;
              color: #999;
            }
          }
          .el-image {
            margin: 20px;
            width: 118px;
            height: 118px;
            border: 1px solid #ddd;
          }
          .footer {
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > p {
              margin-left: 20px;
              color: #999;
            }
            .again {
              display: none;
              margin-right: 20px;
            }
          }
        }
        .emp {
          display: none;
          font-size: 30px;
          color: red;
          margin-right: 20px;
        }
        .sbox:hover {
          background: #f3f2f3;
          .el-button {
            display: inline-block;
          }
        }
      }
      .none_img {
          position: absolute;
          width: 100%;
          height: 300px;
          left: 0;right:0;bottom:0;top: 0;
          margin: auto;
          > .el-image {
            width: 15%;
            margin: 0 auto 20px;
            display: block;
          }
          .none_text{
            text-align: center;
            color: #444;
            font-size: 20px;
          }
        }
    }
  }
}
</style>