<template>
  <div class="userinfo">
    <div class="header">基本信息</div>
    <div class="box">
      <div>企业名称/个人：{{userinfo&&userinfo.companyName}}</div>
      <div>手机号：{{userinfo&&userinfo.mobile}}</div>
      <div>检索次数：{{userinfo&&userinfo.searchTimes}}</div>
      <div>到期时间：{{userinfo&&userinfo.endTime}}</div>
    </div>
    <div class="dqtitil" v-if="userinfo.state == -1 && userinfo.customerSource == '天猫'">
      <p>账号已过期,联系管理员或自行前往天猫店铺续费</p>
    </div>
    <div class="dqtitil" v-if="userinfo.state == -1 && userinfo.customerSource != '天猫'">
      <p>账号已过期,联系管理员续期</p>
    </div>
    <div class="daoqi" v-if="userinfo.state == -1 && userinfo.customerSource == '天猫'">
      <div class="swclass"> 
        <el-image :src="require('@/assets/img/sw.png')"></el-image>
        <div class="swdh">联系电话：18868403698</div>
      </div>
      <div class="tmclass"> 
        <el-image :src="require('@/assets/img/tm.png')" @click="gotm"></el-image>
        <div class="swdh2" @click="gotm">点击前往天猫店铺续费</div>
      </div>
    </div>
    <div class="daoqi" v-if="userinfo.state == -1 && userinfo.customerSource != '天猫'">
      <div class="swclassnotianmao"> 
        <el-image :src="require('@/assets/img/sw.png')"></el-image>
        <div class="swdh">联系电话：18868403698</div>
      </div>
    </div>
    <div class="kaihu" v-if="userinfo.mobile == 'admin'">
      <!-- #region 账号申请 -->
        <el-form
          :model="applyFrom"
          :rules="applyRules"
          ref="applyFrom"
          label-width="150px"
        >
          <el-form-item label="企业名称/姓名" prop="companyName">
            <el-input class="xmclass" v-model="applyFrom.companyName" placeholder="请您输入企业名称/姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input class="xmclass" v-model="applyFrom.contacts" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="旺旺号" prop="wangwang">
            <el-input class="xmclass" v-model="applyFrom.wangwang" placeholder="请输入旺旺号"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobileApply">
            <el-input class="xmclass" v-model="applyFrom.mobileApply" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="passwordApply">
             123456
          </el-form-item>
          <el-form-item label="账号有效期" prop="accountValid">
              <el-select v-model="applyFrom.accountValid" placeholder="账号有效期">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="客户来源" prop="customerSource">
              <el-select v-model="applyFrom.customerSource" placeholder="客户来源">
                <el-option
                  v-for="item in customerSourceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
          </el-form-item>

          <el-form-item style="margin-top:30px;">
            <el-button style="margin-left:90px" type="primary" @click="submitForm">立即开户</el-button>
          </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>
import {basicInfo,submitOpenAccountApply} from '@/api'
export default {
    data(){
      return{
        userinfo: {},
        applyFrom: {
          companyName: undefined,
          contacts: "",
          wangwang: "",
          mobileApply: "",
          accountValid: "",
          customerSource: ""
        },
        applyRules: {
          companyName: [
            { required: true, message: "请您输入企业名称/姓名", trigger: "blur" },
            { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
                {
                          required: true,
                          pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
                          message: '用户名不支持特殊字符',
                          trigger: 'blur'
                }
          ],
          mobileApply: [
            { required: true, message: "请您输入联系电话", trigger: "blur" },
          ],
        },
        options: [{
          value: '一个月',
          label: '一个月'
        }, {
          value: '一年',
          label: '一年'
        }],
        customerSourceOptions: [{
          value: '天猫',
          label: '天猫'
        }, {
          value: '其他',
          label: '其他'
        }],
      }
    },
    created() {
      this.applyFrom.accountValid = this.options[0].value;
      this.applyFrom.customerSource = this.customerSourceOptions[0].value;
     },
    mounted(){
      basicInfo()
      .then(res => {
        this.userinfo = res.result;
      })
    },
    methods: {
      async gotm(){
        window.open("https://detail.tmall.com/item.htm?spm=a1z10.5-b-s.w4011-24001242682.31.6df77a39diBb2D&id=674513712075&rn=84b0769568f77889ac6b4fe9763fd706&abbucket=19&skuId=4857305733942");
      },

      submitForm() {
        this.$refs.applyFrom.validate(vali => {
          if (vali) {
            submitOpenAccountApply(this.applyFrom)
            .then(res => {
              if(res.success){
                this.$alert('开户成功。', '开户结果', {
                confirmButtonText: '确定',
                });
              }
              res.message;
              this.dialogVisible = false;
              this.resetForm('applyFrom')
            })
            .catch((err) => {
              if (err.msg) {
                this.$message.error(err.msg);
              } else {
                this.$message.error(err.msg);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      resetForm(formName) {
      this.$refs[formName].resetFields();
      },


    },
    
}
</script>

<style lang="scss" scoped>
.userinfo{
  width: 80%;
  .header{
    width: 100px;
    padding: 42px 10px 16px;
    border-bottom: 3px solid #2a7efb;
    margin-bottom: 40px;
    margin-left: 15px;
    font-size: 22px;
  }
  .box{
    >div{
      margin: 20px;
    }
  }
  .dqtitil{
    >p{
      text-align: center;
      font-size: 16px;
      color: red;
    }
  }
  .daoqi{
    display: flex;
    text-align: center;
    width: 100%;
    .swclass{
      margin: 50px 130px;
      .el-image{
        width: 200px;
        height: 250px;
      }
      .swdh{
        margin-left: 0px;
        font-size: 15px;
      }
    }
    .swclassnotianmao{
      margin: 50px 380px;
      .el-image{
        width: 200px;
        height: 250px;
      }
      .swdh{
        margin-left: 0px;
        font-size: 15px;
      }
    }
    .tmclass{
      margin: 50px 130px;
      .el-image{
        width: 250px;
        height: 250px;
        cursor: pointer;
      }
      .swdh2{
        margin-left: 0px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }

  .kaihu {
    margin-top: 50px;
    margin-left: 150px;
    .xmclass {
      width: 400px;
    }
  }
}
</style>