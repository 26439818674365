<template>
<div style="padding:30px 0;">
  <div class="user">
      <div class="left">
        <div class="el-icon-s-custom title">个人中心&nbsp;&nbsp;</div>
        <div class="sbox">
          <router-link active-class="active" to="/userinfo">基本信息</router-link>
        </div>
        <div class="sbox">
          <router-link active-class="active" to="/record">我的历史</router-link>
        </div>
        <div class="sbox">
          <router-link active-class="active" to="/collection">我的收藏</router-link>
        </div>
        <div class="sbox">
          <router-link active-class="active" to="/updataList">数据更新</router-link>
        </div>
        <div class="sbox">
          <router-link active-class="active" to="/setpass">修改密码</router-link>
        </div>
      </div>
      <router-view></router-view>
  </div>
</div>
</template>

<script>
export default {
    data(){
      return{
      }
    },
}
</script>

<style lang="scss" scoped>
.user{
  position: relative;
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
  display: flex;
  background: #fff;
  border: 1px solid #ddd;
  .left{
    width: 20%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-right: 1px solid #ddd;
    .title{
      padding: 30px 20px;
      font-size: 24px;
      background: #2a7efb;
      color: #fff;
      cursor: default;
    }
    .sbox{
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      >a{
        color: #333;
        text-decoration: none;
        width: 100%;
        display: inline-block;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
      }
      .active{
          color: #2a7efb;
          border-left: 1px solid#2a7efb;
      }
    }
  }
}
</style>