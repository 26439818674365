<template>
  <div class="home">
    <left-flag @getDbs="getDbs"></left-flag>
    <el-card class="right" style="min-width: 1030px;">
      <div class="header">{{ $t("tab.wait_img") }}</div>
      <div class="js_box">
        <div class="left_box">
          <el-upload
            :show-file-list="false"
            :action="baseImgPath"
            :on-success="handleAvatarSuccess"
            drag
            name="fileData"
          >
            <img v-if="imgContents" :src="imgContents"/>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
              "
              v-else
            >
              <i class="el-icon-plus icon_size"></i>
              <div class="el-upload_text tips">{{ $t("tab.upload_action") }}</div>
              <div class="el-upload_text tips">
                {{ $t("tab.upload_tip1") }}<br />{{ $t("tab.upload_tip2") }}
              </div>
              <div class="el-upload_text tips3">
                <br />{{ $t("tab.upload_tip3") }}
                <br />{{ $t("tab.upload_tip4") }}
                <br />{{ $t("tab.upload_tip5") }}
                <br />{{ $t("tab.upload_tip6") }}
              </div>
            </div>
          </el-upload>
          <div class="his_rec">
            <div
              v-for="(item, index) in his_image"
              :key="index"
              class="his_box"
            >
              <div v-if="item">
                <img
                  @click="handlerChooseImg(item)"
                  :src="item"
                />
              </div>
              <span v-else>暂无历史</span>
            </div>
          </div>
          <div class="clearfix" style="color: #999">
            <span style="font-size: 14px !important;">上传历史</span>
            <!-- <span style="cursor: pointer;" @click="handlerDeleteImg" class="el-icon-delete fr">清除</span> -->
          </div>
        </div>
        <div class="right_box">
          <el-form label-width="150px" :model="form" :rules="rules" ref="form">

            <!-- <div class="search_desc">{{ $t("tab.search_condition") }}</div> -->

            <el-form-item
              :rules="{
                message: $t('tab.please_choose_class_number'),
                trigger: 'change',
                required: true,
              }"
              :label="$t('tab.class_number')"
              prop="locarno_cls"
            >
              <el-input
                @focus="dialogVisible = true"
                readonly
                :placeholder="$t('tab.please_choose')"
                v-model="form.locarno_cls"
              >
                <el-button
                  @click="dialogVisible = true"
                  size="small"
                  slot="suffix"
                  plain
                  >{{ $t("tab.choose") }}</el-button
                >
              </el-input>
            </el-form-item>

            <el-form-item :label="$t('tab.applyer')" prop="applicant_name">
              <el-input
                :placeholder="$t('tab.please_applyer')"
                v-model="form.applicant_name"
              ></el-input>
            </el-form-item>


            <el-form-item :label="$t('tab.pro_name')" prop="title">
              <el-input
                style="width:475px"
                :placeholder="$t('tab.please_pro_name')"
                v-model="form.title"
              ></el-input>
              <span style="color:red;margin-left:10px;font-size: 14px;"><br>建议输入产品关键字缩小范围同时加快检索效率（可选）</span>
            </el-form-item>

            <el-form-item :label="$t('tab.apply_num')" prop="app_num">
              <el-input
                style="width:475px"
                v-model="form.app_num"
                :placeholder="$t('tab.please_apply_num_1')"
              ></el-input>
            </el-form-item>

            <el-form-item style="min-width: 630px;" :label="$t('tab.applyDay')" prop="apply_date">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.app_date_begin"
                type="date"
                :placeholder="$t('tab.startDay')"
              >
              </el-date-picker>
              
              <span style="margin-left:10px;margin-right:10px;">至</span>

              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.app_date_end"
                type="date"
                :placeholder="$t('tab.endDay')"
              >
              </el-date-picker>

            

            </el-form-item>
            <el-form-item :label="$t('tab.leg_status')" prop="legal_state_code">
              <el-radio-group v-model="form.legal_state_code">
                <el-radio label="30">{{ $t("tab.all") }}</el-radio>
                <el-radio label="10">{{ $t("tab.eff") }}</el-radio>
                <el-radio label="20">{{ $t("tab.inv") }}</el-radio>
              </el-radio-group>
                <span style="color:red;margin-left:10px;font-size: 14px;">(法律状态只针对中国文献库有效)</span>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="handlerReset"
                style="margin-right: 10px"
                plain
                >{{ $t("tab.reset") }}</el-button
              >
              <el-button @click="handlerJS" type="primary">{{
                $t("tab.JS")
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
    <!-- 树形结构分类 -->
    <el-dialog :visible.sync="dialogVisible">
      <div slot="title">{{ $t("tab.choose_class_number") }}</div>

      <div v-if="treeTags.length" style="margin-bottom: 4px">
        当前所选：
        <el-tag style="margin-right: 10px" v-for="tag in treeTags" :key="tag">
          {{ tag }}
        </el-tag>
        <el-button
          size="mini"
          style="margin-left: 10px"
          @click="dialogVisible = false"
          type="primary"
          v-if="treeTags.length > 0"
          >确定</el-button
        >
        <el-button
          size="mini"
          style="margin-left: 10px"
          @click="clearhandle"
          type="primary"
          v-if="treeTags.length > 0"
          >清除所选</el-button
        >
      </div>
      <el-input :placeholder="$t('tab.filter_submit')" v-model="filterText">
      </el-input>
      <el-tree
        ref="tree"
        node-key="label"
        :data="data"
        empty-text=""
        :props="defaultProps"
        @check-change="handleCheckChange"
        :filter-node-method="filterNode"
        show-checkbox
        check-strictly
      ></el-tree>
      <div style="font-size: 16px;margin-bottom: 22px;position: relative;margin-top: -25px;margin-left:25px" v-if="filterText && isEmpty">
        未搜索到包含“{{filterText}}”的洛迦诺分类，<font style="color:red">请点击右边-></font>
        <!-- <span @click="recommendHandle" style="color:red;cursor:pointer;">点击我获取推荐分类号</span> -->
        <el-button type="primary" @click="recommendHandle">点击我获取推荐分类号</el-button>
      </div>
      <el-tree
        ref="tree2"
        node-key="label"
        :data="list"
        v-show="filterText && isEmpty && list.length > 0 && isShow"
        :props="defaultProps"
        @check-change="handleCheckRmChange"
        show-checkbox
        check-strictly
      ></el-tree>
      <div v-show="filterText && isEmpty && list.length > 0 && isShow" style="margin-left:25px;margin-top:20px;color:red">请勾选合适的分类号后再点击上方的确定按钮</div>
    </el-dialog>
  </div>
</template>

<script>
import data from "./tree-data";
import { baseImgPath } from "@/utils/env";
import { queryHistoryPic, locarnoClsRecommend } from "@/api/index";
import circularJSON from 'circular-json';
export default {
  data() {
    const dbs = "CN,US,EU,JP,KR,WO,DE,TW,HK,RU,FR";
    return {
      // 上传图片
      imgContents: "",
      baseImgPath,
      //检索表单
      form: {
        title: undefined,
        applicant_name: undefined,
        locarno_cls: undefined,
        apply_date: [],
        app_date_begin:undefined,
        app_date_end:undefined,
        legal_state_code: '30',
      },
      dbs,
      rules: {
        locarno_cls:[{required: true, message: "请选择产品分类号", trigger: "blur"}]
      },
      dialogVisible: false,
      data,
      defaultProps: {
        children: "children",
        label: "label",
      },
      list: [
        
      ],
      isShow: false,
      isEmpty: false,
      filterText: undefined,
      treeTags: [],
      his_image: [],
      filterData: []
    };
  },
  components: {
    leftFlag: () => import("./flag_demo.vue"),
  },
  computed: {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
      this.isEmpty = this.$refs.tree.isEmpty;
      this.getFilterData();
      if (!this.filterText) { 
        this.isShow = false;
      }
    },
    isEmpty(){
      if (!this.isEmpty) {
        let data = this.data;
        
        console.log("this.filterData ", this.filterData)
        this.$nextTick(() => {
          for (let i = 0, len = this.treeTags.length; i < len; i++) {
            for (let j = 0, key = data.length; j < key; j++) {
              if (data[j].children) {
                let treeitem = this.selecthandle(data[j].children, this.treeTags[i]);
                if (treeitem) {
                  this.$refs.tree.setChecked(treeitem, true);
                  break;
                }
              }
            }
          }
        })
      }
      
    }
  },
  mounted() {
    queryHistoryPic().then((res) => {
      this.his_image = res.result;
      this.his_image.length = 4;
    });
  },
  methods: {
    getFilterData(){
      const rootData = this.$refs.tree.root;
      if (rootData.visible) {
        const childNodesStr = circularJSON.stringify(rootData.childNodes);
        const childNodes = circularJSON.parse(childNodesStr);
        this.filterData = this.recursionNodes(childNodes);
      }
    },
    // 循环获取过滤后的分类列表
    recursionNodes(childNodes){
      const nodes = childNodes;
      const result = [];
      for (const item of nodes) {
        if (item.visible) {
          result.push(item.data);
        }
        if (item.childNodes && item.childNodes.length) {
          const tempResult = this.recursionNodes(item.childNodes);
          item.data.children = tempResult;
        }
      } 
      return result;
    },
    // 搜索后分类列表默认选中
    selecthandle(ls,str){
       for (let i = 0, len = ls.length; i < len; i++) {
          if (ls[i].children) {
            return this.selecthandle(ls[i].children, str);
          } else if (ls[i].label.startsWith(str)) {
            return ls[i];
          }
          
        }
    },
    // 获取产品分类列表
    recommendHandle(){
      locarnoClsRecommend({
        title: this.filterText
      }).then(res => {
        if (res.code == 200) {
          let result = res.result || [];
          let list = result.map(item => ({label: item.cls + " " + item.describ}));
          this.list = list;
          this.isShow = true;
          this.$nextTick(() => {
            for (let i = 0, len = this.treeTags.length; i < len; i++) {
              let treeitem = this.selecthandle(list, this.treeTags[i]);
              if (treeitem) {
                this.$refs.tree2.setChecked(treeitem, true);
              }
            }
          })
        }
      })
    },
    
    // 清除选中分类
    clearhandle(){
      
      const res = this.$refs.tree.getCheckedNodes();
      const tree2Ls = this.$refs.tree2.getCheckedNodes();
      this.form.locarno_cls = undefined;
      for (let i = 0, len = res.length; i < len; i++) {
        if (res[i].children) {
            this.$refs.tree.setChecked(res[i], false);
            break;
          } else {
            this.$refs.tree.setChecked(res[i], false);
          }
      }

      tree2Ls.forEach((item) => {
        this.$refs.tree2.setChecked(item, false);
      });
      this.treeTags = [];
    },
    // 图片上传前回调
    handlerBefore(file){
      console.log(file);
    },
    // 获取文献库参数
    getDbs(val) {
      const arr = [];
      val.forEach((item) => {
        arr.push(item.substr(-3, 2));
      });
      this.dbs = arr.toString();
      console.log(this.dbs);
    },
    // 清空历史图片
    handlerDeleteImg() {
      this.$confirm("确定清空历史图片吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.his_image = new Array(4);
      });
    },
    // 选择历史图片
    handlerChooseImg(img) {
      this.imgContents = img;
    },
    // 重置
    handlerReset() {
      this.$refs.form.resetFields();
    },
    // 检索
    handlerJS() {
      if (!this.dbs) {
        this.$message.warning("请选择数据范围");
        return false;
      }
      if (!this.imgContents) {
        this.$message.warning("请上传待检索的图片");
        return false;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          const app_date_begin = this.form.app_date_begin;
          const app_date_end = this.form.app_date_end;
          const params = {
            ...this.form,
            app_date_begin,
            app_date_end,
            imgContents: this.imgContents,
            dbs: this.dbs,
          };
          delete params.apply_date;
          this.$router.push({
            path: "/list",
            query: { params: JSON.stringify(params) },
          });
        }
      });
    },
    //勾选树形结构元素
    handleCheckChange(val, check) {
      
      const res = this.$refs.tree.getCheckedNodes();
      const tree2Ls = this.$refs.tree2.getCheckedNodes();
      if (res.filter(item => !item.children).length + tree2Ls.length > 5) {
        this.$refs.tree.setChecked(val, false);
        this.$message.warning("最多支持五个以内同时检索");
        return false;
      }
      let num = 0 + res.filter(item => !item.children).length + tree2Ls.length;
      if (val.children) {
        let filterData = this.filterData;
        let children = val.children;
        for (let i = 0, len = filterData.length; i < len; i++) {
          if (filterData[i].label == val.label) {
            children = filterData[i].children;
          }
        }
        if (num >= 5) {
          for (let i = 0, len = children.length; i < len; i++) {
            this.$refs.tree.setChecked(children[i], check)
          }
        } else {
          if (check) {
            for (let i = 0, len = (5 >= children.length ? children.length : 5); i < len; i++) {
              for (let j = 0, key = res.length; j < key; j++) {
                if (children[i].label == res[j].label) {
                  num -= 1;
                }
              }
            }
            for (let i = 0, len = ((5 - num) >= children.length ? children.length : (5 - num)); i < len; i++) {
              this.$refs.tree.setChecked(children[i], check)
            }
          } else {
            for (let i = 0, len = children.length; i < len; i++) {
              this.$refs.tree.setChecked(children[i], check)
            }
          }
          
        }
        
      }

      this.form.locarno_cls = undefined;
      res.filter(item=>!item.children).forEach((item) => {
        
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      tree2Ls.forEach((item) => {
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      this.treeTags =
        (this.form.locarno_cls && this.form.locarno_cls.split(",").filter((item, index, self) => self.indexOf(item) == index)) || [];
    },
     //勾选推荐树形结构元素
    handleCheckRmChange(val) {
      const res = this.$refs.tree2.getCheckedNodes();
      const treels = this.$refs.tree.getCheckedNodes();
      if (res.length + treels.filter(item => !item.children).length > 5) {
        this.$refs.tree2.setChecked(val, false);
        this.$message.warning("最多支持五个以内同时检索");
        return false;
      }

      this.form.locarno_cls = undefined;
      treels.filter(item=>!item.children).forEach((item) => {
        
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      res.filter(item=>!item.children).forEach((item) => {
        
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      
      this.treeTags =
        (this.form.locarno_cls && this.form.locarno_cls.split(",").filter((item, index, self) => self.indexOf(item) == index)) || [];
    },
    // 关键字过滤函数
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 工具函数
    getNode(str) {
      return str.substring(0, str.indexOf(" "));
    },
    // 图片上传成功回调
    handleAvatarSuccess(res, file) {
      if (res.code != 200) {
        this.$message.error(res.message);
      }
      console.log(res);
      console.log(file);
      // this.imgContents = URL.createObjectURL(file.raw);
      this.imgContents = res.result.fileUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: space-between;
  margin: 15px 1%;
  // height: calc(100vh - 250px);
  .right {
    width: 83%;
    height: 100%;
    .header {
      font-size: 18px !important;
      margin: 10px;
      font-weight: bold;
    }
    .js_box {
      display: flex;
      .left_box {
        .el-upload {
          .icon_size {
            width: 100px;
            height: 100px;
            font-size: 80px;
            line-height: 80px;
            opacity: 0.6;
          }
          .tips {
            margin-top: 10px;
            opacity: 0.5;
            font-size: 14px;
            line-height: 22px;
          }
          .tips3 {
            margin-top: 10px;
            font-size: 14px;
            line-height: 22px;
            color: red;
            text-align: left;
          }
        }
        .his_rec {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .his_box {
            margin: 10px 0 15px;
            width: 80px;
            height: 70px;
            >div{
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              border: 1px solid #ddd;
              cursor: pointer;
            }
            >div:hover {
              border: 1px solid #12bdd4;
            }
            > span {
              color: #b9bfcb;
              display: inline-block;
              width: 100%;
              height: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              border: 1px solid #ddd;
            }
          }
        }
      }
      .right_box {
        width: 100%;
      }
    }
  }
  .el-dialog {
    height: 600px;
  }
}
</style>
<style>
.home .el-dialog__header {
  background: rgb(0, 183, 255);
  color: #fff;
  font-size: 20px;
}
.home .el-upload-dragger {
  height: 400px;
}
.home .el-dialog__body {
  height: 600px !important;
  overflow: auto;
  padding: 10px;
}
.search_desc {
  margin-left: 85px;
  margin-bottom: 10px;
  font-size: 14px;
  color: red;
}
</style>