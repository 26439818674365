<template>
  <div>条款</div>
</template>

<script>
export default {

}
</script>

<style lanng="scss" scoped>

</style>