<template>
  <div class="collection">
    <div class="header">我的收藏</div>

    <el-tabs style="margin: 0 20px" v-model="activeName" type="card">
      <el-tab-pane label="我收藏的专利" name="first">
        <div class="content">
          <el-form ref="form" inline :model="form">
            <el-form-item prop="dbs">
              <el-select v-model="form.dbs" placeholder="全部文献库">
                <el-option
                  v-for="item in dbsList"
                  :key="item.key"
                  :label="item.key"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="legal_state_code">
              <el-select
                v-model="form.legalStateCode"
                placeholder="全部法律状态"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.key"
                  :label="item.key"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="c_time">
              <el-date-picker
                v-model="form.c_time"
                type="daterange"
                range-separator="至"
                start-placeholder="收藏开始日期"
                end-placeholder="收藏结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="handlerSumbit" type="primary">查询</el-button>
            </el-form-item>
          </el-form>
          <div class="box" v-loading="loading" element-loading-text="请稍等...">
            <div v-if="tableData.length > 0" class="contentBox">
              <div
                v-for="(item, index) in tableData"
                :key="index"
                class="s_box"
              >
                <div class="img_box">
                  <el-image
                    fit="contain"
                    @click="goDetail(item.pid)"
                    class="l_image"
                    :src="
                      item.imageInfos &&
                      item.imageInfos[0] &&
                      item.imageInfos[0].image_path
                    "
                    lazy
                  >
                  </el-image>
                  <span
                    v-if="item.legalStateCode != 30"
                    class="status"
                    :class="{ active: item.legalStateCode == 10 }"
                    >{{
                      item.legalStateCode == 10
                        ? "有效"
                        : item.legalStateCode == 20
                        ? "无效"
                        : ""
                    }}</span
                  >
                </div>

                <div class="hide">
                  <div>
                    {{ $t("tab.pro_name") + "：" + item.title }}
                  </div>
                  <div>{{ $t("tab.apply_num") + "：" + item.appNum }}</div>
                  <div>{{ $t("tab.applyDay") + "：" + item.appDate }}</div>
                  <div>{{ $t("tab.open_num") + "：" + item.pubNum }}</div>
                  <div>{{ $t("tab.open_day") + "：" + item.pubDate }}</div>
                  <div v-if="item.applicantName">
                    {{ $t("tab.applyer") + "：" }}
                    <span v-for="(val, i) in item.applicantName" :key="val + i"
                      >{{ val }};</span
                    >
                  </div>
                  <div>
                    {{ $t("tab.patentee") + "：" }}
                    <span v-for="(val, i) in item.patenteeName" :key="val + i"
                      >{{ val }};</span
                    >
                  </div>
                  <div>
                    {{ $t("tab.class_number") + "：" }}
                    <span v-for="(val, i) in item.locarnoCls" :key="val + i"
                      >{{ val }};</span
                    >
                  </div>
                  <div>文献库：{{ item.dataSource }}</div>
                </div>
                <div style="flex: 1">
                  <button
                  style="font-size: 16px;"
                    @click="handlerDel(item.pid, index)"
                    class="el-icon-star-on fr cp"
                  >
                    取消收藏
                  </button>
                </div>
              </div>
            </div>
            <div class="none_img" v-else>
              <el-image :src="require('@/assets/img/tab/none.png')"></el-image>
              <div class="none_text">暂无收藏</div>
            </div>
          </div>
        </div>

        <div class="clearfix">
          <el-pagination
            class="fr"
            hide-on-single-page
            :page-count="pages"
            @current-change="handlerPageChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我收藏的检索条件" name="second">
        <div class="content2">
          <div v-if="his_list.length > 0" class="box2">
            <div v-for="(item, index) in his_list" :key="item.id" class="sbox">
              <div class="title">
                <span v-for="(val, i) in item.expCondtionList" :key="i">{{
                  val
                }}</span>
              </div>
              <el-image
                fit="contain"
                v-if="item.imageUrl"
                :src="item.imageUrl"
              ></el-image>
              <div class="footer">
                <p v-if="item.createTime">{{ item.createTime }}</p>
                <el-button
                  @click="handlerJS(item.conditionInfo)"
                  type="primary"
                  class="again"
                  >重新检索</el-button
                >
                <div class="pot">
                  <button
                    style="font-size: 16px;"
                    @click="handlerDel2(item.id, index)"
                    class="el-icon-star-on fr cp"
                  >
                    取消收藏
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="none_img" v-else>
            <el-image :src="require('@/assets/img/tab/none.png')"></el-image>
            <div class="none_text">暂无历史</div>
          </div>
        </div>

        <div class="clearfix">
          <el-pagination
            class="fr"
            hide-on-single-page
            :page-count="pages"
            @current-change="handlerPageChange2"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  queryCollectionInfo,
  collectionCondition,
  collectPatent,
  queryHistoryInfo,
  collection,
} from "@/api";
export default {
  data() {
    return {
      form: {
        dbs: undefined,
        legalStateCode: undefined,
        c_time: [],
      },
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      dbsList: [],
      stateList: [],
      pages: 1,
      tableData: [],
      loading: false,
      activeName: "first",
      pageSize2: 10,
      pageNo2: 1,
      totalPages: 0,
      his_list: [],
    };
  },
  mounted() {
    this.handlerSumbit();
    this.getOption();
    this.handlerSumbit2();
  },
  methods: {
    // 检索
    handlerJS(params) {
      this.$router.push({ path: "/list", query: { params } });
    },
    // tab切换
    handlerSumbit2() {
      this.loading = true;
      const params = {
        isCollection: 1,
        pageNo: this.pageNo2,
        pageSize: this.pageSize2,
      };
      queryHistoryInfo(params).then((res) => {
        this.his_list = res.result.records;
        this.totalPages = res.result.pages;
        this.loading = false;
      });
    },
    // 历史分页查询
    handlerPageChange2(val) {
      console.log("val--", val);
      this.his_list = [];
      this.pageNo2 = val;
      this.handlerSumbit2();
    },
    // 新窗口打开详情页
    goDetail(pid) {
      const routeData = this.$router.resolve({
        path: "/detail",
        query: { pid },
      });
      window.open(routeData.href, "_blank");
    },
    // 取消收藏
    handlerDel(pid, index) {
      this.$confirm("确认要取消收藏吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        collectPatent({ pid, state: 0 }).then(() => {
          this.$delete(this.tableData, index);
          this.$message({
            type: "success",
            message: "取消收藏成功!",
          });
        });
      });
    },
    // 取消收藏2
    handlerDel2(pid, index) {
      this.$confirm("确认要取消收藏吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        collection({ state: 0, searchConditonId: pid }).then(() => {
          this.$delete(this.his_list, index);
          this.$message({
            type: "success",
            message: "取消收藏成功!",
          });
        });
      });
    },
    // 列表查询
    handlerSumbit() {
      this.loading = true;
      const collectionTimeBegin =
        (this.form.c_time && this.form.c_time[0]) || "";
      const collectionTimeEnd = (this.form.c_time && this.form.c_time[1]) || "";
      const params = {
        ...this.form,
        collectionTimeBegin,
        collectionTimeEnd,
        ...this.page,
      };
      delete params.c_time;
      queryCollectionInfo(params).then((res) => {
        this.tableData = res.result.records;
        this.pages = res.result.pages;
        this.loading = false;
      });
    },

    // 分页查询
    handlerPageChange(val) {
      this.tableData = [];
      this.page.pageNo = val;
      this.handlerSumbit();
    },
    // 获取下拉选项
    getOption() {
      collectionCondition().then((res) => {
        this.dbsList = res.result.dataSourceList;
        this.stateList = res.result.stateCodeList;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.collection {
  width: 80%;
  .header {
    width: 100px;
    padding: 42px 10px 16px;
    border-bottom: 2px solid #2a7efb;
    margin-bottom: 40px;
    margin-left: 15px;
    font-size: 22px;
  }
  .content {
    // margin: 0 20px;
    .box {
      height: 600px;
      position: relative;
      .contentBox {
        height: 100%;
        border: 1px solid #ddd;
        overflow: auto;
        .s_box {
          margin: 4px;
          padding: 8px;
          border: 1px solid #ddd;
          display: flex;
          .img_box {
            position: relative;
            margin-right: 8px;
            .el-image {
              width: 238px;
              height: 218px;
              cursor: pointer;
            }
            .status {
              position: absolute;
              right: 5px;
              top: 5px;
              background: #adb2b6;
              color: #fff;
              padding: 2px;
            }
            .active {
              background: #4bb966;
            }
          }
          .hide {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
        }
      }
      .none_img {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        > .el-image {
          width: 15%;
          margin: 0 auto 20px;
          display: block;
        }
        .none_text {
          text-align: center;
          // color: #444;
          font-size: 20px;
        }
      }
    }
  }
  .el-tabs {
    margin: 0 20px;
    .el-tab-pane {
      height: 600px;
      .box2 {
        width: 100%;
        height: 100%;
        overflow: auto;
        .sbox {
          box-sizing: border-box;
          width: 100%;
          height: 300px;
          border: 1px solid #ddd;
          position: relative;
          .title {
            margin: 20px;
            > span {
              display: inline-block;
              border: 1px solid #ddd;
              padding: 10px;
              margin-right: 15px;
              color: #999;
            }
          }
          .el-image {
            margin: 20px;
            width: 118px;
            height: 118px;
            border: 1px solid #ddd;
          }
          .footer {
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .pot {
              top: -220px;
              right: 20px;
              position: absolute;
            }
            > p {
              margin-left: 20px;
              color: #999;
            }
            .again {
              display: none;
              margin-right: 20px;
            }
          }
        }
        .emp {
          display: none;
          font-size: 30px;
          color: red;
          margin-right: 20px;
        }
        .sbox:hover {
          background: #f3f2f3;
          .el-button {
            display: inline-block;
          }
        }
      }
      .none_img {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        > .el-image {
          width: 15%;
          margin: 0 auto 20px;
          display: block;
        }
        .none_text {
          text-align: center;
          color: #444;
          font-size: 20px;
        }
      }
    }
  }
}
</style>