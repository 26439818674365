export default [
  {
    label: '01 食品',
    children: [
      {label: '01-01 烘制食品、饼干、点心、意大利面制品及其他谷类食品，巧克力，糖果类，冰冻食品'},
      {label: '01-02 水果、蔬菜和水果蔬菜制品'},
      {label: '01-03 奶酪、黄油及其代用品、其他奶制品'},
      {label: '01-04 肉制品（包括猪肉制品）、鱼肉制品'},
      {label: '01-05 豆腐和豆腐制品'},
      {label: '01-06 动物食品'},
      {label: '01-99 其他杂项'}
    ]
  },
  {
    label: '02 服装、服饰用品和缝纫用品',
    children: [
      {label: '02-01 内衣、女内衣、妇女紧身胸衣、乳罩和睡衣'},
      {label: '02-02 服装'},
      {label: '02-03 帽子、头部遮盖物'},
      {label: '02-04 鞋、短袜和长袜'},
      {label: '02-05 领带、围巾、颈巾和手帕'},
      {label: '02-06 手套'},
      {label: '02-07 缝纫用品、服饰用品和服装附件'},
      {label: '02-99 其他杂项'},
    ]
  },
  {
    label: '03 其他类未列入的旅行用品、箱包、阳伞和个人用品',
    children: [
      {label: '03-01 大衣箱、手提箱、公文包、手提包、钥匙袋、钱夹、专门为内容物设计的箱包和类似物品'},
      {label: '03-02 ［空缺］'},
      {label: '03-03 雨伞、阳伞、遮阳篷和手杖'},
      {label: '03-04 扇子'},
      {label: '03-05 用于婴儿、儿童的携带及辅助行走装置'},
      {label: '03-99 其他杂项'},
    ]
  },
  {
    label: '04 刷子',
    children: [
      {label: '04-01 清洁刷和扫帚'},
      {label: '04-02 梳妆刷、服装刷和鞋刷'},
      {label: '04-03 机器用刷'},
      {label: '04-04 油漆刷和烹饪用刷'},
      {label: '04-99 其他杂项'},
    ]
  },
  {
    label: '05 纺织品，人造或天然材料片材',
    children: [
      {label: '05-01 纺纱制品'},
      {label: '05-02 花边'},
      {label: '05-03 刺绣品'},
      {label: '05-04 缎带、编带和其他装饰品'},
      {label: '05-05 纺织纤维制品'},
      {label: '05-06 人造或天然材料片材类'},
      {label: '05-99 其他杂项'},
    ]
  },
  {
    label: '06 家具和家居用品',
    children: [
      {label: '06-01 坐椅'},
      {label: '06-02 床'},
      {label: '06-03 桌子及类似家具'},
      {label: '06-04 存放物品用家具'},
      {label: '06-05 组合家具'},
      {label: '06-06 其他家具和家具部件'},
      {label: '06-07 镜子和框架'},
      {label: '06-08 挂衣架'},
      {label: '06-09 床垫和垫子'},
      {label: '06-10 窗帘、门帘和室内百叶帘'},
      {label: '06-11 地毯、地席、地垫和小地毯'},
      {label: '06-12 挂毯'},
      {label: '06-13 毛毯或其他覆盖材料、家用亚麻织物和餐桌用布'},
      {label: '06-99 其他杂项'},
    ]
  },
  {
    label: '07 其他类未列入的家用物品',
    children: [
      {label: '07-01 瓷器、玻璃器皿、餐用盘碟和其他类似物品'},
      {label: '07-02 烹调用具、器具和容器'},
      {label: '07-03 桌上用刀、叉、匙等餐具'},
      {label: '07-04 用于制作食物或饮料的手工操作器具和用具'},
      {label: '07-05 熨烫用具、洗涤用具、清洁用具和干燥用具'},
      {label: '07-06 其他桌上用品'},
      {label: '07-07 其他家用容器'},
      {label: '07-08 壁炉配件'},
      {label: '07-09 家用器具和用具的立架及支架'},
      {label: '07-99 其他杂项'},
    ]
  },
  {
    label: '08 工具和金属器具',
    children: [
      {label: '08-01 钻孔、磨铣或挖掘工具和器具'},
      {label: '08-02 锤和其他类似工具及器具'},
      {label: '08-03 切削刀具和器具'},
      {label: '08-04 螺丝起子及其他类似工具和器具'},
      {label: '08-05 其他工具和器具'},
      {label: '08-06 把手、球形捏手和铰链'},
      {label: '08-07 锁紧或关闭装置'},
      {label: '08-08 其他类未包括的夹紧、支撑或安装装置'},
      {label: '08-09 其他大类或小类未包括的用于门、窗、家具的金属配件、金属装配件及类似物品'},
      {label: '08-10 自行车和摩托车支架'},
      {label: '08-11 窗帘用五金件'},
      {label: '08-99 其他杂项'},
    ]
  },
  {
    label: '09 用于商品运输或装卸的包装和容器',
    children: [
      {label: '09-01 瓶、长颈瓶、鼓形瓶、盛装腐蚀性液体的大玻璃瓶、细颈坛和带有动力分配装置的容器'},
      {label: '09-02 储物铁罐、圆桶和木桶'},
      {label: '09-03 盒子、箱子、集装箱、防腐罐或盒'},
      {label: '09-04 有盖提篮、柳条筐和篮子'},
      {label: '09-05 袋、香袋、管和囊'},
      {label: '09-06 绳和捆绑材料'},
      {label: '09-07 密封装置及附件'},
      {label: '09-08 叉车的货盘和装卸台'},
      {label: '09-09 废物和垃圾容器及其座架'},
      {label: '09-10 运输或搬运包装物及容器用提手和握柄'},
      {label: '09-99 其他杂项'},
    ]
  },
  {
    label: '10 钟、表和其他计量仪器、检测和信号仪器',
    children: [
      {label: '10-01 钟和闹钟'},
      {label: '10-02 表和手表'},
      {label: '10-03 其他计时仪器'},
      {label: '10-04 其他计量仪器、设备和装置'},
      {label: '10-05 检测、安全和试验用仪器、设备和装置'},
      {label: '10-06 信号设备和装置'},
      {label: '10-07 测量仪器、检测仪器和信号仪器的外壳、盘面、指针和所有其他零部件及附件'},
      {label: '10-99 其他杂项'},
    ]
  },
  {
    label: '11 装饰品',
    children: [
      {label: '11-01 珠宝首饰'},
      {label: '11-02 小装饰品，桌子、壁炉台和墙的装饰，花瓶和花盆'},
      {label: '11-03 纪念章和徽章'},
      {label: '11-04 人造的花、水果和植物'},
      {label: '11-05 旗帜、节日装饰品'},
      {label: '11-99 其他杂项'},
    ]
  },
  {
    label: '12 运输或提升工具',
    children: [
      {label: '12-01 畜力车辆'},
      {label: '12-02 手推车、单轮手推车'},
      {label: '12-03 机车、铁路车辆及其他全部有轨车辆'},
      {label: '12-04 高架索车、吊椅和滑雪索车'},
      {label: '12-05 装载和运输用电梯和升降机'},
      {label: '12-06 船和艇'},
      {label: '12-07 飞机和空间运载工具'},
      {label: '12-08 汽车、公共汽车和货车'},
      {label: '12-09 拖拉机'},
      {label: '12-10 公路车辆的拖车'},
      {label: '12-11 自行车和摩托车'},
      {label: '12-12 婴儿车、病人用椅、担架'},
      {label: '12-13 专用车辆'},
      {label: '12-14 其他交通工具'},
      {label: '12-15 车辆轮胎和防滑链'},
      {label: '12-16 其他类未包括的车辆零件、装置和附件'},
      {label: '12-17 铁路基础设施零件'},
      {label: '12-99 其他杂项'},
    ]
  },
  {
    label: '13 发电、配电和输电的设备',
    children: [
      {label: '13-01 发电机和电动机'},
      {label: '13-02 电力变压器、整流器、电池和蓄电池'},
      {label: '13-03 配电和电力控制设备'},
      {label: '13-04 太阳能设备'},
      {label: '13-99 其他杂项'},
    ]
  },
  {
    label: '14 录音、通讯或信息再现设备',
    children: [
      {label: '14-01 声音或图像的记录或复制设备'},
      {label: '14-02 数据处理设备及相关的仪器、装置'},
      {label: '14-03 通讯设备和无线遥控器、无线电放大器'},
      {label: '14-04 显示图像和图标'},
      {label: '14-05 记录数据和存储数据的介质'},
      {label: '14-06 其他类未列入的电子设备用支架、立架和支撑装置'},
      {label: '14-99 其他杂项'},
    ]
  },
  {
    label: '15 其他类未列入的机械',
    children: [
      {label: '15-01 发动机'},
      {label: '15-02 泵和压缩机'},
      {label: '15-03 农业机械'},
      {label: '15-04 建筑机械'},
      {label: '15-05 洗涤、清扫和干燥机械'},
      {label: '15-06 纺织、缝纫、针织和绣花机械及零部件'},
      {label: '15-07 制冷机械及设备'},
      {label: '15-08 [空缺]'},
      {label: '15-09 机床、研磨和铸造机械'},
      {label: '15-10 填装、打包和包装机械'},
      {label: '15-99 其他杂项'},
    ]
  },
  {
    label: '16 照相、电影摄影和光学仪器',
    children: [
      {label: '16-01 照相机和电影摄影机'},
      {label: '16-02 放映机和看片器'},
      {label: '16-03 影印机械和放大机'},
      {label: '16-04 显影仪器和设备'},
      {label: '16-05 附件'},
      {label: '16-06 光学制品'},
      {label: '16-99 其他杂项'},
    ]
  },
  {
    label: '17 乐器',
    children: [
      {label: '17-01 键盘乐器'},
      {label: '17-02 管乐器'},
      {label: '17-03 弦乐器'},
      {label: '17-04 打击乐器'},
      {label: '17-05 机械乐器'},
      {label: '17-99 其他杂项'},
    ]
  },
  {
    label: '18 印刷和办公机械',
    children: [
      {label: '18-01 打字机和计算器'},
      {label: '18-02 印刷机'},
      {label: '18-03 印刷活字和活字版'},
      {label: '18-04 装订机、印刷工用订书机、切纸机和修边机（用于装订）'},
      {label: '18-99 其他杂项'},
    ]
  },
  {
    label: '19 文具用品、办公设备、美术用品及教学材料',
    children: [
      {label: '19-01 书写纸、通信和通知用卡片'},
      {label: '19-02 办公设备'},
      {label: '19-03 日历'},
      {label: '19-04 书本及其他与其外观相似的物品。'},
      {label: '19-05 [空缺]'},
      {label: '19-06 用于写字、绘图、绘画、雕塑、雕刻和其他工艺的材料和器械'},
      {label: '19-07 教学材料'},
      {label: '19-08 其他印刷品'},
      {label: '19-99 其他杂项'},
    ]
  },
  {
    label: '20 销售和广告设备、标志',
    children: [
      {label: '20-01 自动售货机'},
      {label: '20-02 陈列和销售设备'},
      {label: '20-03 标志、指示牌和广告装置'},
      {label: '20-99 其他杂项'},
    ]
  },
  {
    label: '21 游戏器具、玩具、帐篷和体育用品',
    children: [
      {label: '21-01 游戏器具和玩具'},
      {label: '21-02 体操和运动器械及设备'},
      {label: '21-03 其他娱乐和游艺用品'},
      {label: '21-04 帐篷及其附件'},
      {label: '21-99 其他杂项'},
    ]
  },
  {
    label: '22 武器、烟火用具、用于狩猎、捕鱼及捕杀有害动物的器具',
    children: [
      {label: '22-01 射击武器'},
      {label: '22-02 其他武器'},
      {label: '22-03 弹药、火箭和烟火用品'},
      {label: '22-04 靶及附件'},
      {label: '22-05 狩猎和捕鱼器械'},
      {label: '22-06 捕捉器、捕杀有害动物的用具'},
      {label: '22-99 其他杂项'},
    ]
  },
  {
    label: '23 液体分配设备，卫生、供暖、通风和空调设备，固体燃料',
    children: [
      {label: '23-01 液体分配设备'},
      {label: '23-02 卫生设备'},
      {label: '23-03 加热设备'},
      {label: '23-04 通风和空调设备'},
      {label: '23-05 固体燃料'},
      {label: '23-06 个人卫生用卫生设备'},
      {label: '23-07 便溺设备'},
      {label: '23-08 其他大类或小类未包括的卫生设备及附件'},
      {label: '23-99 其他杂项'},
    ]
  },
  {
    label: '24 医疗和实验室设备',
    children: [
      {label: '24-01 医生、医院和实验室用的固定器械和设备'},
      {label: '24-02 医疗器械、供实验室用的仪器和工具'},
      {label: '24-03 修复手术用具'},
      {label: '24-04 用于包扎伤口、护理和医疗的材料'},
      {label: '24-99 其他杂项'},
    ]
  },
  {
    label: '25 建筑构件和施工元件',
    children: [
      {label: '25-01 建筑材料'},
      {label: '25-02 预制或预装建筑部件'},
      {label: '25-03 房屋、车库和其他建筑'},
      {label: '25-04 梯、梯子和脚手架'},
      {label: '25-99 其他杂项'},
    ]
  },
  {
    label: '26 照明设备',
    children: [
      {label: '26-01 烛台和烛架'},
      {label: '26-02 手电、手提灯和灯笼'},
      {label: '26-03 公共场所照明装置'},
      {label: '26-04 光源、电或非电器具'},
      {label: '26-05 灯、落地灯、枝形吊灯、墙壁和天花板装置、灯罩、反光罩、照相和电影聚光灯'},
      {label: '26-06 车辆照明设备'},
      {label: '26-99 其他杂项'},
    ]
  },
  {
    label: '27 烟草和吸烟用具',
    children: [
      {label: '27-01 烟草、雪茄和香烟'},
      {label: '27-02 烟斗、雪茄和香烟的烟嘴'},
      {label: '27-03 烟灰缸'},
      {label: '27-04 火柴'},
      {label: '27-05 打火机'},
      {label: '27-06 雪茄盒、香烟盒、烟草罐和烟袋'},
      {label: '27-99 其他杂项'},
    ]
  },
  {
    label: '28 药品、化妆品、梳妆用品和器具',
    children: [
      {label: '28-01 药品'},
      {label: '28-02 化妆用品'},
      {label: '28-03 梳妆用品和美容室设备'},
      {label: '28-04 假发、人造毛发'},
      {label: '28-05 空气清新剂'},
      {label: '28-99 其他杂项'},
    ]
  },
  {
    label: '29 防火灾、防事故救援装置和设备',
    children: [
      {label: '29-01 防火灾装置和设备'},
      {label: '29-02 其他类未列入的防事故和救援装置及设备'},
      {label: '29-99 其他杂项'},
    ]
  },
  {
    label: '30 动物的管理与训养设备',
    children: [
      {label: '30-01 动物服装'},
      {label: '30-02 围栏、笼、房及类似居所'},
      {label: '30-03 喂食器和喂水器'},
      {label: '30-04 鞍具'},
      {label: '30-05 鞭子和刺棒'},
      {label: '30-06 睡垫和窝'},
      {label: '30-07 栖木和其他笼子配件'},
      {label: '30-08 打印器、标记牌和脚镣'},
      {label: '30-09 拴柱'},
      {label: '30-10 动物梳洗用品'},
      {label: '30-11 动物排泄盒和排泄物清除装置'},
      {label: '30-12 动物用玩具'},
      {label: '30-99 其他杂项'}
    ]
  },
  {
    label: '31 其他类未列入的食品或饮料制作机械和设备',
    children: [
      {label: '31-00 其他类未列入的食品或饮料制作设备'},
    ]
  },
  {
    label: '32 图形符号和标志，表面图案，装饰',
    children: [
      {label: '32-00 图形符号和标志，表面图案，装饰'},
    ]
  },
]