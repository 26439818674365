<template>
  <div>
    <div class="demo-image__preview"></div>
    <el-card class="l_header" style="width: 98%; margin: 10px auto">
      <div class="list_header">
        <span class="fl back" @click="$router.go(-1)"
          ><div class="el-icon-back"></div>
          {{ $t("tab.return") }}</span
        >
        <img @click="imgflagEvent(true)" v-if="params.imgContents" :src="params.imgContents" />
        
     
        <div class="box">
          <div class="infobox">
            <i>检索条件</i>
            <span v-if="params.national_cls"
              >国家分类号：{{ params.national_cls }}</span
            >
            <span v-if="params.locarno_cls"
              >产品分类号：{{ params.locarno_cls }}</span
            >
            <span v-if="params.applicant_name"
              >申请人：{{ params.applicant_name }}</span
            >
            <span v-if="params.patentee_name"
              >专利权人：{{ params.patentee_name }}</span
            >
            <span v-if="params.title">产品关键字：{{ params.title }}</span>
            <span v-if="params.designer_name"
              >设计人：{{ params.designer_name }}</span
            >
            <span v-if="params.app_num">申请号：{{ params.app_num }}</span>
            <span v-if="params.pub_num">公开号：{{ params.pub_num }}</span>
            <span v-if="params.abs">简要说明：{{ params.abs }}</span>
            <span v-if="params.legal_state_code"
              >法律状态：{{
                params.legal_state_code == 30
                  ? "全部"
                  : params.legal_state_code == 10
                  ? "有效"
                  : "无效"
              }}</span
            >

            <span v-if="params.app_date_begin || params.app_date_end">
              申请日：
              <span v-if="params.app_date_begin">
                {{ params.app_date_begin }}
              </span>
              <span v-else>最早</span>

              <span>{{ $t("tab.to") }}</span>

              <span v-if="params.app_date_end">
                {{ params.app_date_end }}
              </span>
              <span v-else>至今</span>
            </span>

            <span v-if="params.pub_date_begin || params.pub_date_end">
              公开日：
              <span v-if="params.pub_date_begin">
                {{ params.pub_date_begin }}
              </span>
              <span v-else>最早</span>

              <span>{{ $t("tab.to") }}</span>

              <span v-if="params.pub_date_end">
                {{ params.pub_date_end }}
              </span>
              <span v-else>至今</span>
            </span>
          </div>
          <div class="btnbox">
            <span @click="isTitle = !isTitle" @mouseover="iconEvent(true)" @mouseleave="iconEvent(false)" class="hide_title">
              <!-- <i class="el-icon-view"></i> -->
              <img style="width: 16px;height: 16px;vertical-align:middle;" :src="isTitle ? activeFlag ? active_iconOne : iconOne : activeFlag ? active_iconTwo : iconTwo" alt="">
              {{ isTitle ? $t("tab.hide_text") : $t("tab.dis_text") }}</span>
            <span @click="deriveData" class="hide_title"
              ><i class="el-icon-document"></i>导出选中数据</span
            >
            <span v-if="searchConditionId" @click="Collection" class="hide_title"
              ><i :class="cflag ? 'el-icon-star-off' : 'el-icon-star-on'"></i>{{cflag ? '收藏检索条件' : '取消收藏检索条件'}}</span
            >
          </div>
        </div>
        <div class="end">
          检索到：<span style="color: #12a8bc; margin: 0">{{
            totalElements
          }}</span
          >条专利
        </div>
      </div>
      <div class="imgpot" v-show="imgflag">
        <span @click="imgflagEvent(false)">x</span>
          <img :src="params.imgContents" alt="">
        </div>
    </el-card>
    <div class="home">
      <left-flag
        :dbs="dbs"
        :citie_js_nums="citie_js_nums"
        @screen="handlerScreen"
      ></left-flag>
      <el-card
        v-loading="loading"
        style="min-width: 1020px"
        class="right"
        :element-loading-text="$t('tab.loading_text')"
      >
        <div v-if="tableData.length || [] > 0" class="list_box">
          <div
            class="s_box"
            v-for="(item, index) in tableData || []"
            :key="index"
          >
            <div class="flex_center cp" @click="goDetail(item.pid)">
              <img
                class="l_image"
                :src="item.image_infos && item.image_infos[0].image_path"
              />
            </div>
            <span :class="{ selck: true, active_selck: isShow(item.pid) }">
              <input type="checkbox" v-model="allpid" :value="item.pid" />
              <span>选中</span>
            </span>
            <span
              v-if="item.legal_state_code"
              class="status"
              :class="{ active: item.legal_state_code == 10 }"
              >{{ item.legal_state_code == 10 ? "有效" : "无效" }}</span
            >
            <div v-if="isTitle" class="hide">
              <span class="title">
                {{ $t("tab.pro_name") + "：" + item.title }}
              </span>
              <span>{{ $t("tab.apply_num") + "：" + item.app_num[0] }}</span>
              <span v-if="item.applicant_name && item.applicant_name[0]">{{
                $t("tab.applyer") + "：" + item.applicant_name[0]
              }}</span>
              <span v-if="item.patentee_name && item.patentee_name[0]">{{
                $t("tab.patentee") + "：" + item.patentee_name[0]
              }}</span>
              <span v-if="item.designer_name && item.designer_name[0]">{{
                $t("tab.designer") + "：" + item.designer_name[0]
              }}</span>
              <span v-if="item.image_score">{{
                $t("tab.js_pf") + "：" + item.image_score
              }}</span>
            </div>
          </div>
        </div>
        <div class="none_img" v-show="shownone" v-else>
          <img :src="require('@/assets/img/tab/none.png')" />
          <div class="none_text">未发现相似图形，请检查检索条件是否有误！</div>
        </div>
      </el-card>
    </div>
    <div class="l_footer clearfix">
      <el-pagination
        class="fr"
        hide-on-single-page
        :page-count="totalPages"
        @current-change="handlerPageChange"
        :page-size="pageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { queryByKeyword, checkAccount, selckdata, collection } from "@/api";
export default {
  data() {
    return {
      tableData: [],
      isTitle: true,
      loading: false,
      params: {},
      pageNum: 1,
      pageSize: 36,
      totalElements: 0,
      totalPages: 0,
      citie_js_nums: [],
      dbs: [],
      shownone: true,
      allpid: [],
      searchConditionId: "",
      url: "",
      iconOne:require("@/assets/img/yz.png"),
      iconTwo:require("@/assets/img/yb.png"),
      active_iconOne:require("@/assets/img/active_yz.png"),
      active_iconTwo:require("@/assets/img/active_yb.png"),
    imgflag:false,
    cflag:true,
    activeFlag:false,
    };
  },
  components: {
    leftFlag: () => import("./flag_demo.vue"),
  },
  mounted() {
    const params = JSON.parse(this.$route.query.params);
    this.params = params;
    console.log("this.params", this.params);
    this.dbs = params.dbs ? params.dbs.split(",") : [];
    console.log(this.dbs);
    this.getTableDateBefore();
  },
  methods: {
    iconEvent(type){
this.activeFlag = type
    },
    imgflagEvent(bol){
      this.imgflag = bol
    },
    // 收藏检索条件
    Collection() {
      
      collection({ state:  this.cflag ? 1 : 0, searchConditonId: this.searchConditionId }).then(
        () => {
          this.$message.success(this.cflag ? "收藏成功" : "取消收藏成功");
              this.cflag = !this.cflag
        }
      );

  
    },
    // 导出数据
    deriveData() {
      if (!this.allpid.length) {
        this.$message.warning("请先选中数据再进行导出");
        return;
      }

      let str = this.allpid.join(",");

      
      const loading = this.$loading({
          lock: true,
          text: '文件导出中...',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass:"acColor"
        });
      selckdata({ pidList: str }).then((res) => {
        // 请求成功后下载文件
        if (res != null && res.status == 200) {
          // 生成blob对象 定义下载格式
          let blob = new Blob([res.data], { type: res.type });
          // 获取文件名
          let filename = res.headers["content-disposition"];
          filename = decodeURIComponent(filename.split("filename=")[1]);
          // 创建 a标签 执行下载
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = filename; //下载后文件名
          document.body.appendChild(downloadElement); // 项目插入a元素
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放blob对象
          loading.close();
        }else{
          loading.close();
        }
      }).catch(() => {
         loading.close();
      });
    },

    isShow(id) {
      let flag = this.allpid.some((val) => {
        return val == id;
      });

      return !flag;
    },
    // 筛选
    handlerScreen(val) {
      const arr = [];
      val.forEach((item) => {
        arr.push(item.substr(-3, 2));
      });
      this.params.dbs = arr.toString();
      this.getTableDateBefore();
    },
    // 新窗口打开详情页
    goDetail(pid) {
      const routeData = this.$router.resolve({
        path: "/detail",
        query: { pid },
      });
      window.open(routeData.href, "_blank");
    },
    // 分页查询
    handlerPageChange(val) {
      this.tableData = [];
      this.pageNum = val;
      this.getTableDateBefore();
    },

    getTableDateBefore() {
      checkAccount().then((res) => {
        let that = this;
        if (res.result) {
          this.getTableData();
        } else {
          this.$message({
            message: "账号使用已过有效期，请联系管理员！",
            type: "error",
            onClose() {
              that.$router.push("/userinfo");
            },
          });
          //this.$router.push('/userinfo')
        }
      });
    },
    // 获取列表数据
    getTableData() {
      this.shownone = false;
      this.loading = true;
      queryByKeyword({ ...this.params, pgIndex: this.pageNum }).then((res) => {
        this.loading = false;
        this.pageNum = res.pageNum;
        this.totalElements = res.totalElements;
        this.totalPages = res.totalPages;
        this.tableData = res.elements;
        this.citie_js_nums = res.stat.data_source || [];
        this.searchConditionId = res.searchConditionId;
        this.shownone = true;
        console.log(this.citie_js_nums);
        console.log(this.tableData);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.l_header {
  min-width: 1280px;
}
 .imgpot{
   top: 180px;
    position: fixed;
    z-index: 50000;
    box-shadow: 1px 1px 10px 1px rgba(153, 153, 153,0.6);
     > img{
       max-width: 240px;
     }
    > span{
      position: absolute;
      cursor: pointer;
      right: 5px;
      top: 5px;
      font-size: 18px;
      width: 25px;
      line-height: 25px;
      text-align: center;
      height: 25px;
      border-radius: 50%;
    }
  }
.list_header {
  width: 100%;
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  span {
    margin-right: 10px;
  }
  i {
    margin-right: 10px;
    padding: 10px;
  }
  .end {
    align-self: flex-end;
    position: absolute;
    right: 10px;
  }
  .back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    border: 1px solid #ddd;
    padding: 0 10px;
    cursor: pointer;
    > div {
      font-size: 32px;
    }
  }
  .back:hover {
    color: #2dbdd0;
  }
  > img {
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #ddd;
  }
 

  .box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .infobox {
      color: #999;
      > span {
        border: 1px solid #ddd;
        padding: 5px;
      }
    }
    .hide_title {
      border: 1px solid #2b7efa;
      color: #2b7efa;
      width: 80%;
      text-align: center;
      padding: 5px;
      cursor: pointer;
      margin-left: 10px;
      > i {
        padding: 0 2px;
        margin: 0;
      }
    }
    .hide_title:hover {
      background: #2b7efa;
      color: #fff;
    }
  }
}
.home {
  display: flex;
  justify-content: space-between;
  margin: 15px 1%;
  .right {
    min-width: 1005px;
    position: relative;
    width: 83%;
    // height: calc(100vh - 250px);
    overflow: auto;
    .none_img {
      position: absolute;
      width: 100%;
      height: 300px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      > img {
        width: 15%;
        // height: 100%;
        margin: 0 auto 20px;
        display: block;
      }
      .none_text {
        text-align: center;
        color: #444;
        font-size: 20px;
      }
    }
    .list_box {
      display: flex;
      flex-wrap: wrap;

      .s_box {
        width: 238px;
        margin: 4px;
        border: 1px solid #ddd;
        position: relative;
        &:hover .selck {
          display: block;
        }
        .flex_center {
          width: 100%;
          height: 218px;
        }
        .active_selck {
          display: none;
        }
        .selck {
          position: absolute;
          width: 234px;
          transition: all 0.2s;
          left: 0px;
          bottom: 0px;
          opacity: 0.7;
          background: #333;
          color: #fff;
          padding: 5px 0 5px 5px;
          > input {
            margin-right: 6px;
            width: 15px;
            height: 15px;
            border: none;
            vertical-align: middle;
          }
          > span {
            vertical-align: middle;
          }
        }
        .status {
          position: absolute;
          right: 5px;
          top: 5px;
          background: #adb2b6;
          color: #fff;
          padding: 2px;
        }
        .active {
          background: #4bb966;
        }
        .hide {
          display: flex;
          flex-direction: column;
          > span {
            padding: 6px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .s_box:hover {
        border: 1px solid #666;
      }
    }
  }
}
.l_footer {
  margin-right: 30px;
}
</style>

<style>
.el-card__body {
  padding: 10px;
}
</style>