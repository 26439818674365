<template>
  <div class="updata">
    <div class="header">数据更新</div>
    <!-- <div class="table">

    </div> -->
    <el-table
      size="small"
      stripe
      :data="tableData"
      border
    >
      <el-table-column
        class-name="br_none"
        label-class-name="br"
        prop="seq"
        align="center"
        width="80px"
        label="序号"
      ></el-table-column>
      <el-table-column
        prop="cname"
        align="center"
        width="80px"
        label="文献库"
      ></el-table-column>
      <el-table-column
        prop="appDateEarliest"
        align="center"
        label="最早申请日"
      ></el-table-column>
      <el-table-column
        prop="appDateLast"
        align="center"
        label="最晚申请日"
      ></el-table-column>
      <el-table-column
        prop="total"
        align="center"
        label="专利总数"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        align="center"
        label="最后更新日期"
      ></el-table-column>
      <el-table-column
        prop="updateFrequency"
        align="center"
        label="更新频率"
      ></el-table-column>
    </el-table>
    <div class="tips">
      <p>*国内数据一般情况下一周两次更新（周二、周五），特殊情况下最迟滞后一周</p>
      <p>*国外数据每月更新一次，但因为数据来源方提供数据的不稳定，部分文件库数据可能会有滞后</p>

    </div>
  </div>
</template>

<script>
import { dataSourceList } from "@/api";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取数据列表
    getTableData() {
      dataSourceList().then((res) => {
        this.tableData = res.result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.updata {
  width: 80%;
  .header {
    width: 100px;
    padding: 42px 10px 16px;
    border-bottom: 2px solid #2a7efb;
    margin-bottom: 40px;
    margin-left: 15px;
    font-size: 22px;
  }
  .el-table {
    width: 90%;
    margin: 0 20px;
  }
  .tips{
    margin: 30px;
    color: #444;
  }
}
</style>
<style>
 :not(.br).br_none{
  border-right: none !important;
}
</style>