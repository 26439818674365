<template>
  <div>
    <div class="regis">
      <div class="header">
        <router-link to="/login" class="el-icon-arrow-left">{{
          $t("system.toLogin")
        }}</router-link>
      </div>
      <div class="title">{{ $t("system.regis") }}</div>
      <el-form class="regis_form" ref="form" :rules="rules" :model="form">
        <el-form-item prop="mobile">
          <el-input
            :placeholder="$t('system.please_phone')"
            v-model="form.mobile"
          ></el-input>
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-input
            :placeholder="$t('system.please_code')"
            v-model="form.smsCode"
          >
            <el-button @click="getCode" type="text" slot="suffix">{{
             isCode ? $t("system.getCode") : count + 's' + $t('system.again_code')
            }}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            autocomplete="new-password"
            show-password
            :placeholder="$t('system.please_pass')"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="repeatPass">
          <el-input
            show-password
            :placeholder="$t('system.again_pass')"
            v-model="repeatPass"
          ></el-input>
        </el-form-item>
        <div class="footer">
          <el-button @click="handlerRegis" type="primary">{{ $t("system.yes") }}</el-button>
          <div class="tips">{{ $t("system.tips") }}</div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getCode,regis} from '@/api'
export default {
  data() {
    return {
      form: {
        mobile: undefined,
        password: undefined,
        smsCode: undefined,
      },
      repeatPass: undefined,
      rules: {
        mobile: [
          {
            required: true,
            message: this.$t("system.phone_rules"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("system.pass_rules"),
            trigger: "blur",
          },
        ],
        smsCode: [
          {
            required: true,
            message: this.$t("system.code_rules"),
            trigger: "blur",
          },
        ],
      },
      timer: null,
      count: 60,
      isCode: true
    };
  },
  methods:{
    // 提交注册
    handlerRegis(){
      this.$refs.form.validate(vali => {
        if(vali){
          if(this.form.password === this.repeatPass){
            regis(this.form)
            .then(res => {
              this.$message.success({
                message:this.$t('system.regis_success'),
                onClose: () => {
                  this.$router.push('/login');
                },
                duration: 1000
                })
              console.log(res);
            })
          }else{
            this.$message.warning(this.$t('system.pass_tip'))
          }
        }
      })
    },
    // 获取验证码
    getCode(){
      this.$refs.form.validateField('mobile',vali => {
        if(!vali){
          //发送验证码，倒计时。
          getCode({mobile: this.form.mobile,smsmode: '1'})
          .then(res => {
            this.isCode = false
            this.$message.success(res.message);
            this.timer = setInterval(() => {
              this.count--;
              if(this.count <= 0){
                clearInterval(this.timer);
                this.timer = null;
                this.count = 60;
                this.isCode = true
              }
            }, 1000);
          })
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.regis {
  width: 600px;
  height: 500px;
  background: #fff;
  border: 1px solid #ddd;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  .header {
    > a {
      margin: 20px;
      text-decoration: none;
      color: rgb(8, 125, 243);
      font-size: 16px;
    }
  }
  .title {
    text-align: center;
    font-size: 20px;
  }
  .regis_form {
    width: 430px;
    margin: 26px auto;
    .footer {
      width: 100%;
      .el-button {
        width: 100%;
      }
      .tips {
        margin-top: 16px;
        text-align: center;
        color: #aaa;
      }
    }
  }
}
</style>