<template>
  <div>
    <img class="login_logo" src="../../assets/img/login_logo.png" alt="" srcset="">
    <div class="login">
      <div class="title">{{ $t("system.title") }}</div>
      <el-form
        class="login_form"
        label-width="0px"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-form-item prop="mobile" >
          <el-input v-model="form.mobile" prefix-icon="el-icon-s-custom" placeholder="请输入账号/手机号"></el-input>
        </el-form-item>
        <el-form-item prop="password" >
          <el-input type="password" v-model="form.password" prefix-icon="el-icon-lock" show-password placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: -20px">
          <el-checkbox v-model="isAuth"><div>自动登录</div></el-checkbox>
          <span style="float:right">
          <router-link class="new" to="/forgetPass">{{$t("system.forget_pass")}}</router-link>
          <!-- <router-link class="new" to="/regis">{{$t("system.regis")}}</router-link> -->
          </span>
        </el-form-item>
        <div class="login_footer">
          <el-button @click="checkLoginProtection" type="primary">{{$t("system.sign_in")}}</el-button>
        </div>
      </el-form>
      <p class="tianz">
        还没账号？<span @click="setuser">立即申请 >></span>
      </p>

      <el-dialog
        title="账号申请"
        :visible.sync="dialogVisible"
        width="600px"
        top="30vh"
        :close-on-click-modal="false"
        @close="offsetuser"
      >
        <!-- #region 账号申请 -->
        <el-form
          :model="applyFrom"
          :rules="applyRules"
          ref="applyFrom"
          label-width="150px"
          style="padding-right: 80px;"
        >
          <el-form-item label="企业名称" prop="companyName">
            <el-input v-model="applyFrom.companyName" placeholder="请您输入企业名称/姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobileApply">
            <el-input v-model="applyFrom.mobileApply" placeholder="请输入您的联系电话"></el-input>
          </el-form-item>
          <!-- <el-form-item label="手机号" prop="companyName">
            <el-input v-model="setfrom.companyName"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="手机号" prop="">
            <el-input v-model="setfrom.useriphone"></el-input>
          </el-form-item> -->
          <el-form-item style="margin-top:30px;">
            <el-button style="margin-left:50px" type="primary" @click="submitForm">立即申请</el-button>
            <el-button style="margin-left:40px" @click="resetForm('applyFrom')">重置</el-button>
            <div class="tips">若申请遇到问题,可联系客服:4000-315-426</div>
          </el-form-item>
        </el-form>
        <!-- #endregion -->
      </el-dialog>


    </div>
    <div class="enterprise_footer" style="width:1000px;text-align:center">杭州信拾贝网络科技有限公司 浙ICP备18028232号 建议使用Chrome浏览器</div>
  </div>
</template>

<script>
import {login,checkLoginProtection,submitEvidenceApply} from '@/api'
export default {
  data() {
    return {
      form: {
        mobile: undefined,
        password: undefined,
      },
      isAuth: false,
      applyFrom: {
        companyName: undefined,
        mobileApply: "",
      },
      dialogVisible: false,
      rules: {
        mobile: [
          {
            required: true,
            message: this.$t("system.phone_rules"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("system.pass_rules"),
            trigger: "blur",
          },
        ],
      },
      applyRules: {
        companyName: [
          { required: true, message: "请您输入企业名称/姓名", trigger: "blur" },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
               {
                        required: true,
                        pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
                        message: '用户名不支持特殊字符',
                        trigger: 'blur'
               }
        ],
        mobileApply: [
          { required: true, message: "请您输入联系电话", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    checkLoginProtection(){
          this.$refs.form.validate(vali => {
          if(vali){
            checkLoginProtection(this.form)
            .then(res => {
              if(res.result.loginFlag){
                    this.$confirm('此用户已经在其他设备登录，是否在其他设备登出，并在此设备登录？用户点击确认后才能登录', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      this.handlerLogin();                    
                    }).catch(() => {
                      this.$message({
                        type: 'info',
                        message: '已取消登录'
                      });          
                    });
              }else{
                this.handlerLogin();
              }
            })
          }
        })
        },


    handlerLogin(){
      // if(this.isAuth){
        this.$refs.form.validate(vali => {
          if(vali){
            login(this.form)
            .then(res => {
              localStorage.setItem('TOKEN',res.result.token);
              localStorage.setItem('userInfo',JSON.stringify(res.result.userInfo));
              this.$message.success({
                message: res.message,
                onClose: () => {
                  this.$router.push('/')
                },
                duration: 1000
              })
            })
          }
        })
      // }else{
      //   this.$message.warning(this.$t('system.please'));
      // }
    },


    setuser() {
      this.dialogVisible = true;
    },
    offsetuser(){
       this.resetForm('applyFrom')
    },

    // #region 做修改
    submitForm() {
      this.$refs.applyFrom.validate(vali => {
        if (vali) {
          // alert("submit!");
          submitEvidenceApply(this.applyFrom)
          .then(res => {
            // this.$message.success(
            //   "系统使用申请提交成功，我们会尽快与您取得联系，请您保持手机畅通。"
            // );

            if(res.success){
              this.$alert('系统使用申请提交成功，我们会尽快与您取得联系，请您保持手机畅通。', '申请结果', {
              confirmButtonText: '确定',
              });
            }

            res.message;
            this.dialogVisible = false;
            this.resetForm('applyFrom')
          })
          .catch((err) => {
            if (err.msg) {
              this.$message.error(err.msg);
            } else {
              // this.$message({
              //   message: "系统繁忙，请稍后再试",
              //   type: "warning",
              // });
            }
            // this.imgCode();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // #endregion
  }
};
</script>

<style lang="scss" scoped>
.login_logo{
  position: fixed;
  top: 30px;
  left: 50px;
  width: 320px;
}
.login {
  width: 600px;
  height: 440px;
  background: #fff;
  border: 1px solid #ddd;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
  .title {
    text-align: center;
    font-size: 32px;
    margin-top: 35px;
    font-weight: bold;
  }
  .tianz {
    font-size: 16px;
    padding-top: 10px;
    text-align: center;
    span {
      cursor: pointer;
      color: #4186e9;
    }
  }
  .login_form {
    margin: 0 auto;
    width: 400px;
    margin-top: 45px;
    .el-form-item {
      margin-bottom: 40px;
    }
    .el-input {
      width: 100%;
    }
    .clause {
      color: rgb(0, 84, 153);
    }
    .new {
      margin-right: 20px;
      color: rgb(0, 84, 153);
      font-size: 15px;
    }
    .el-button{
      font-size: 18px;
      margin-top: -18px;
      margin-bottom: 15px;
    }
    .login_footer{
      width: 100%;
      text-align: center;
      .el-button {
        width: 100%;
      }
    }
  }
}

.enterprise_footer{
  width: 260px;
  height: 40px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  color: #ededed;
}
</style>
<style>
.el-form-item__label {
  font-size: 18px;
}

.tips {
  text-align: center;
  color: #aaa;
  font-size: 15px;
  margin-bottom: 0px;
  margin-left: 0px;
}
</style>