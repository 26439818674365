<template>
  <div class="detail">
    <div class="header">
      <span class="tab">
        <p @click="active = 0" :class="{ active: active == 0 }">著录项</p>
        <p v-if="data&&data.legalStateInfos&&data.legalStateInfos.length > 0" @click="active = 1" :class="{ active: active == 1 }">法律事务信息</p>
      </span>
      <button style="font-size: 16px;" plain @click="handlerColl" class="fr el-icon-star-off cp" :class="{'el-icon-star-on':isColl}">{{isColl ? '取消收藏' : '收藏'}}</button>
    </div>
    <div v-show="active == 0" class="forminfo">
      <div class="form">
        <div v-for="(item,index) in data.patentDetailKVList " :key="index" class="box">
          <div class="lable">{{item.myKey}}</div>
          <div class="title">{{item.myValue}}</div>
        </div>
      </div>
      <div class="img_box">
        <div
        class="title_box"
        v-for="val in data.imageInfos"
        :key="val.image_num">
        <div class="image_box">
          <el-image
            fit="contain"
            :preview-src-list="srcList"
            :src="val.image_path"
          ></el-image>
        </div>
          <span>{{val.image_desc}}</span>
        </div>
      </div>
    </div>

    <!-- 法律事务信息 -->
    <div v-show="active == 1" class="tableinfo">
      <div v-for="(item,index) in data&&data.legalStateInfos" :key="index" class="table">
        <div class="tr">
          <div class="td">公开日</div>
          <div class="td">{{item.legal_state_date}}</div>
        </div>
        <div class="tr">
          <div class="td">法律状态</div>
          <div class="td">{{item.legal_state_name}}</div>
        </div>
        <div class="tr">
          <div class="td">法律事务信息</div>
          <div class="td">{{item.legal_state_code}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { designPatentDetail,collectPatent } from "@/api";
export default {
  data() {
    return {
      active: 0,
      data: {},
      srcList: [],
      isColl: false
    };
  },
  mounted() {
    designPatentDetail({ pid: this.$route.query.pid || 1 }).then((res) => {
      console.log(res);
      this.data = res.result || {};
      this.isColl = res.result.collectionState == 1 ? true : false
      this.data.imageInfos.forEach(item => {
          this.srcList.push(item.image_path)
      });
    });
  },
  methods: {
    // 收藏、取消收藏
    handlerColl(){
      this.isColl = !this.isColl
      const params = {pid:this.$route.query.pid,state: this.isColl ? 1 : 0}
      collectPatent(params)
      .then(() => {
        this.$message.success('操作成功')
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  width: 80%;
  min-height: 90vh;
  margin: 30px auto;
  border: 1px solid #e6e6e6;
  .header {
    margin: 30px 100px 30px 50px;
    .tab {
      display: inline-flex;
      border: 3px solid #2b7efa;
      > p {
        color: #2b7efa;
        padding: 10px;
        cursor: pointer;
      }
      .active {
        background: #2b7efa;
        color: #fff;
      }
    }
  }
  .forminfo {
    width: 80%;
    margin: 50px auto;
    line-height: 20px;
    .form{
      display: grid;
      grid-template-columns: repeat(2,50%);
      .box{
        display: flex;
        margin: 20px 0;
        .lable{
          width: 200px;
          text-align: right;
          color: #999;
        }
        .title{
          flex: 1;
          margin-left: 40px;
          word-break: break-all;
        }
      }
    }
    .img_box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    .title_box{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 50px;
      .image_box{
        width: 300px;
        height: 300px;
        border: 1px solid #ddd;
        margin-bottom: 5px;
        .el-image{
           width: 300px;
           height: 300px;
          // >img{
          //   max-width: 100%;
          //   max-height: 100%;
          // }
        }
      }
      >span{
        display: inline-block;
        width: 300px;
        text-align: center;
        word-break: break-all;
      }
    }
    }
  }
  .tableinfo{
    width: 80%;
    margin: 50px auto;
    .table{
      border: 1px solid #e6e6e6;
      color: #8a8b8d;
      margin-bottom: 30px;
      .tr{
        display: flex;
        .td{
          padding: 10px 0;
        }
        .td:not(:last-child){
          border-right: 1px solid #e6e6e6;
          flex: 1;
        }
        .td:last-child{
          text-align: center;
          width: 1000px;
          padding: 10px;
        }
      }
      .tr:not(:last-child){
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
}
</style>