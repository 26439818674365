<template>
  <div>
    <el-form
      class="setPass"
      label-width="100px"
      ref="form"
      :rules="rules"
      :model="form"
    >
      <el-form-item label="原密码" prop="orgPassword">
        <el-input type="password" placeholder="请输入原密码" v-model="form.orgPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" placeholder="请输入新密码" v-model="form.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="againPass">
        <el-input type="password" placeholder="再次输入新密码" v-model="form.againPass"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handlerSetPass" type="primary">确认</el-button>
        <el-button @click="$refs.form.resetFields()" type="primary">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {updatePassword} from '@/api'
export default {
  data() {
    return {
      form: {
        orgPassword: undefined,
        newPassword: undefined,
        againPass: undefined,
      },
      rules: {
        orgPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        againPass: [
          { required: true, message: "再次输入密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handlerSetPass(){
      this.$refs.form.validate(valid => {
        if(valid){
          if(this.form.againPass === this.form.newPassword){
            let that = this;
            updatePassword(this.form)
            .then(res => {
              this.$message({
                  message:res.message,
                  type: 'success',
                  onClose(){
                    console.log(11);
                    that.$router.push('/login')
                  }
                })
              console.log(res);
            })
          }else {
            this.$message.warning('两次密码不一致');
          }
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.setPass {
  margin: 120px;
}
</style>
<style>
.setPass .el-form-item__label {
  font-size: 16px;
}
</style>