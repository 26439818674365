<template>
  <div class="demo">
    <span v-for="item in data" :key="item">{{item}}</span>
  </div>
</template>

<script>
export default {
    data(){
      return{
        data: [1,2,3,4,5,6,7,8,9,10,11,12,13]
      }
    },
}
</script>

<style lang="scss" scoped>
.demo{
  display: grid;
  width: 50%;
  margin: 50px auto;
  // grid-template-columns: repeat(3, 33.33%);
  // grid-template-rows: repeat(4, 33.33%);
  grid-template-columns: repeat(auto-fill, 100px);
  >span{
    background: yellow;
    margin: 0 10px;
    text-align: center;
  }
}
</style>