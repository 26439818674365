<template>
  <div class="home">
    <left-flag @getDbs="getDbs"></left-flag>
    <el-card class="right" style="min-width: 1030px;">
      <el-form label-width="120px" :model="form" :rules="rules" ref="form">
        <el-form-item :label="$t('tab.apply_num')" prop="app_num">
          <el-input
            style="width:360px"
            v-model="form.app_num"
            :placeholder="$t('tab.please_apply_num')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.open_num')" prop="pub_num">
          <el-input
            style="width:360px"
            v-model="form.pub_num"
            :placeholder="$t('tab.please_open_num')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.class_number')" prop="locarno_cls">
          <el-input
            style="width:360px"
            @focus="dialogVisible = true"
            readonly
            v-model="form.locarno_cls"
            :placeholder="$t('tab.choose_class_number')"
          >
            <template slot="suffix">
              <el-button
                @click="dialogVisible = true"
                type="text"
                class="el-icon-search"
              ></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.national_number')" prop="national_cls">
          <el-input
            style="width:360px"
            v-model="form.national_cls"
            :placeholder="$t('tab.please_national_num')"
          >
            <el-tooltip
              popper-class="tip_content"
              slot="suffix"
              placement="bottom"
              effect="light"
            >
              <div slot="content">
                本国分类指各国国家内部的分类体系，是
                对国际分类的补充。目前系统中只有美、
                日、韩三个国家的外观设计中有本国分类
                号，可以参照如下格式输入本国分类号进 行检索：
                B5-11AC（韩国本国分类号示 例），B5-10（日本本国分类号示例），
                D2899（美国本国分类号示例）
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.pro_name')" prop="title">
          <el-input
            style="width:360px"
            v-model="form.title"
            :placeholder="$t('tab.please_pro_name')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.designer')" prop="designer_name">
          <el-input
            style="width:360px"
            v-model="form.designer_name"
            :placeholder="$t('tab.please_designer')"
          ></el-input>
        </el-form-item>



        <el-form-item style="width:480px" :label="$t('tab.applyDay')" prop="apply_date" >
          <el-date-picker
                style="width:165px"
                value-format="yyyy-MM-dd"
                v-model="form.app_date_begin"
                type="date"
                :placeholder="$t('tab.startDay')"
              >
          </el-date-picker>

          <span style="margin-left:5px;margin-right:5px;">至</span>

          <el-date-picker
                style="width:169px"
                value-format="yyyy-MM-dd"
                v-model="form.app_date_end"
                type="date"
                :placeholder="$t('tab.endDay')"
              >
          </el-date-picker>
        </el-form-item>


        <el-form-item style="width:480px" :label="$t('tab.open_day')" prop="open_date" >
          <el-date-picker
                style="width:165px"
                value-format="yyyy-MM-dd"
                v-model="form.pub_date_begin"
                type="date"
                :placeholder="$t('tab.startDay')"
              >
          </el-date-picker>

          <span style="margin-left:5px;margin-right:5px;">至</span>

          <el-date-picker
                style="width:169px"
                value-format="yyyy-MM-dd"
                v-model="form.pub_date_end"
                type="date"
                :placeholder="$t('tab.endDay')"
              >
          </el-date-picker>
        </el-form-item>

        

        <el-form-item :label="$t('tab.patentee')" prop="patentee_name">
          <el-input
            style="width:360px"
            v-model="form.patentee_name"
            :placeholder="$t('tab.please_patentee')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.applyer')" prop="applicant_name">
          <el-input
            style="width:360px"
            v-model="form.applicant_name"
            :placeholder="$t('tab.please_applyer')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.bri_desc')" prop="abs">
          <el-input
            style="width:360px"
            v-model="form.abs"
            :placeholder="$t('tab.please_bri_desc')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('tab.leg_status')" prop="legal_state_code">
          <el-radio-group v-model="form.legal_state_code">
            <el-radio label="30">{{ $t("tab.all") }}</el-radio>
            <el-radio label="10">{{ $t("tab.eff") }}</el-radio>
            <el-radio label="20">{{ $t("tab.inv") }}</el-radio>
          </el-radio-group>
              <span style="color:red;margin-left:10px;font-size: 14px;">(法律状态只针对中国文献库有效)</span>
        </el-form-item>
        <div class="footer">
          <el-button @click="handlerReset" style="margin-right: 10px" plain>{{
            $t("tab.reset")
          }}</el-button>
          <el-button @click="handlerJS" type="primary">{{
            $t("tab.JS")
          }}</el-button>
        </div>
      </el-form>
    </el-card>
    <!-- 树形结构分类 -->
    <el-dialog :visible.sync="dialogVisible">
      <div slot="title">{{ $t("tab.choose_class_number") }}</div>
      <div v-if="treeTags.length" style="margin-bottom: 4px">
        当前所选：
        <el-tag style="margin-right: 10px" v-for="tag in treeTags" :key="tag">
          {{ tag }}
        </el-tag>
        <el-button
          size="mini"
          @click="dialogVisible = false"
          type="primary"
          v-if="treeTags.length > 0"
          >确定</el-button
        >

        <el-button
          size="mini"
          style="margin-left: 10px"
          @click="clearhandle"
          type="primary"
          v-if="treeTags.length > 0"
          >清除所选</el-button
        >
      </div>
      <el-input :placeholder="$t('tab.filter_submit')" v-model="filterText">
      </el-input>
      <el-tree
        ref="tree"
        node-key="label"
        :data="data"
        empty-text=""
        :props="defaultProps"
        @check-change="handleCheckChange"
        :filter-node-method="filterNode"
        show-checkbox
        check-strictly
      ></el-tree>

      <div style="font-size: 16px;margin-bottom: 22px;position: relative;margin-top: -25px;margin-left:25px" v-if="filterText && isEmpty">
        未搜索到包含“{{filterText}}”的洛迦诺分类，<font style="color:red">请点击右边-></font>
        <!-- <span @click="recommendHandle" style="color:red;cursor:pointer;">点击我获取推荐分类号</span> -->
        <el-button type="primary" @click="recommendHandle">点击我获取推荐分类号</el-button>
      </div>
      <el-tree
        ref="tree2"
        node-key="label"
        :data="list"
        v-show="filterText && isEmpty && list.length > 0 && isShow"
        :props="defaultProps"
        @check-change="handleCheckRmChange"
        show-checkbox
        check-strictly
      >
      </el-tree>
      <div v-show="filterText && isEmpty && list.length > 0 && isShow" style="margin-left:25px;margin-top:20px;color:red">请勾选合适的分类号后再点击上方的确定按钮</div>
    </el-dialog>
  </div>
</template>

<script>
import data from "./tree-data";
import {locarnoClsRecommend } from "@/api/index";
import circularJSON from 'circular-json';
export default {
  data() {
    const dbs = "CN,US,EU,JP,KR,WO,DE,TW,HK,RU,FR";
    return {
      form: {
        app_num: undefined,
        pub_num: undefined,
        locarno_cls: undefined,
        national_cls: undefined,
        title: undefined,
        designer_name: undefined,
        apply_date: [],
        app_date_begin:undefined,
        app_date_end:undefined,
        open_date: [],
        pub_date_begin:undefined,
        pub_date_end:undefined,
        patentee_name: undefined,
        applicant_name: undefined,
        abs: undefined,
        legal_state_code: "30",
      },
      dbs,
      rules: {},
      dialogVisible: false,
      data,
      defaultProps: {
        children: "children",
        label: "label",
      },
      list: [
        
      ],
      filterText: undefined,
      treeTags: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
      this.isEmpty = this.$refs.tree.isEmpty;
      this.getFilterData();
      if (!this.filterText) { 
        this.isShow = false;
      }
    },
    isEmpty(){
      if (!this.isEmpty) {
        let data = this.data;
        
        console.log("this.filterData ", this.filterData)
        this.$nextTick(() => {
          for (let i = 0, len = this.treeTags.length; i < len; i++) {
            for (let j = 0, key = data.length; j < key; j++) {
              if (data[j].children) {
                let treeitem = this.selecthandle(data[j].children, this.treeTags[i]);
                if (treeitem) {
                  this.$refs.tree.setChecked(treeitem, true);
                  break;
                }
              }
            }
          }
        })
      }
      
    }
  },
  components: {
    leftFlag: () => import("./flag_demo.vue"),
  },
  methods: {

    getFilterData(){
      console.info('111')
      const rootData = this.$refs.tree.root;
      if (rootData.visible) {
        const childNodesStr = circularJSON.stringify(rootData.childNodes);
        const childNodes = circularJSON.parse(childNodesStr);
        this.filterData = this.recursionNodes(childNodes);
      }
    },
    // 循环获取过滤后的分类列表
    recursionNodes(childNodes){
      const nodes = childNodes;
      const result = [];
      for (const item of nodes) {
        if (item.visible) {
          result.push(item.data);
        }
        if (item.childNodes && item.childNodes.length) {
          const tempResult = this.recursionNodes(item.childNodes);
          item.data.children = tempResult;
        }
      } 
      return result;
    },
    // 搜索后分类列表默认选中
    selecthandle(ls,str){
       for (let i = 0, len = ls.length; i < len; i++) {
          if (ls[i].children) {
            return this.selecthandle(ls[i].children, str);
          } else if (ls[i].label.startsWith(str)) {
            return ls[i];
          }
          
        }
    },
    // 获取产品分类列表
    recommendHandle(){
      locarnoClsRecommend({
        title: this.filterText
      }).then(res => {
        if (res.code == 200) {
          let result = res.result || [];
          let list = result.map(item => ({label: item.cls + " " + item.describ}));
          this.list = list;
          this.isShow = true;
          this.$nextTick(() => {
            for (let i = 0, len = this.treeTags.length; i < len; i++) {
              let treeitem = this.selecthandle(list, this.treeTags[i]);
              if (treeitem) {
                this.$refs.tree2.setChecked(treeitem, true);
              }
            }
          })
        }
      })
    },

    // 清除选中分类
    clearhandle(){
      const res = this.$refs.tree.getCheckedNodes();
      const tree2Ls = this.$refs.tree2.getCheckedNodes();
      this.form.locarno_cls = undefined;
      for (let i = 0, len = res.length; i < len; i++) {
        if (res[i].children) {
            this.$refs.tree.setChecked(res[i], false);
            break;
          } else {
            this.$refs.tree.setChecked(res[i], false);
          }
      }

      tree2Ls.forEach((item) => {
        this.$refs.tree2.setChecked(item, false);
      });
      this.treeTags = [];
    },



    // 获取文献库参数
    getDbs(val) {
      const arr = [];
      val.forEach((item) => {
        arr.push(item.substr(-3, 2));
      });
      this.dbs = arr.toString();
      console.log(this.dbs);
    },
    // 关键字过滤函数
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    
    //勾选树形结构元素
    handleCheckChange(val, check) {
      
      const res = this.$refs.tree.getCheckedNodes();
      const tree2Ls = this.$refs.tree2.getCheckedNodes();
      if (res.filter(item => !item.children).length + tree2Ls.length > 5) {
        this.$refs.tree.setChecked(val, false);
        this.$message.warning("最多支持五个以内同时检索");
        return false;
      }
      let num = 0 + res.filter(item => !item.children).length + tree2Ls.length;
      if (val.children) {
        let filterData = this.filterData;
        let children = val.children;
        for (let i = 0, len = filterData.length; i < len; i++) {
          if (filterData[i].label == val.label) {
            children = filterData[i].children;
          }
        }
        if (num >= 5) {
          for (let i = 0, len = children.length; i < len; i++) {
            this.$refs.tree.setChecked(children[i], check)
          }
        } else {
          if (check) {
            for (let i = 0, len = (5 >= children.length ? children.length : 5); i < len; i++) {
              for (let j = 0, key = res.length; j < key; j++) {
                if (children[i].label == res[j].label) {
                  num -= 1;
                }
              }
            }
            for (let i = 0, len = ((5 - num) >= children.length ? children.length : (5 - num)); i < len; i++) {
              this.$refs.tree.setChecked(children[i], check)
            }
          } else {
            for (let i = 0, len = children.length; i < len; i++) {
              this.$refs.tree.setChecked(children[i], check)
            }
          }
          
        }
        
      }

      this.form.locarno_cls = undefined;
      res.filter(item=>!item.children).forEach((item) => {
        
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      tree2Ls.forEach((item) => {
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      this.treeTags =
        (this.form.locarno_cls && this.form.locarno_cls.split(",").filter((item, index, self) => self.indexOf(item) == index)) || [];
    },
     //勾选推荐树形结构元素
    handleCheckRmChange(val) {
      const res = this.$refs.tree2.getCheckedNodes();
      const treels = this.$refs.tree.getCheckedNodes();
      if (res.length + treels.filter(item => !item.children).length > 5) {
        this.$refs.tree2.setChecked(val, false);
        this.$message.warning("最多支持五个以内同时检索");
        return false;
      }

      this.form.locarno_cls = undefined;
      treels.filter(item=>!item.children).forEach((item) => {
        
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      res.filter(item=>!item.children).forEach((item) => {
        
        if (this.form.locarno_cls) {
          this.form.locarno_cls += "," + this.getNode(item.label);
        } else {
          this.form.locarno_cls = this.getNode(item.label);
        }
      });
      
      this.treeTags =
        (this.form.locarno_cls && this.form.locarno_cls.split(",").filter((item, index, self) => self.indexOf(item) == index)) || [];
    },

    // 工具函数
    getNode(str) {
      return str.substring(0, str.indexOf(" "));
    },
    // 重置
    handlerReset() {
      this.$refs.form.resetFields();
    },
    // 检索
    handlerJS() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const app_date_begin = this.form.app_date_begin;
          const app_date_end = this.form.app_date_end;

          const pub_date_begin = this.form.pub_date_begin;
          const pub_date_end = this.form.pub_date_end;

          const params = {
            ...this.form,
            app_date_begin,
            app_date_end,
            pub_date_begin,
            pub_date_end,
            imgContents: this.imgContents,
            dbs: this.dbs,
          };
          delete params.apply_date;
          delete params.open_date;
          console.log(params);
          // delete params.open_date;
          this.$router.push({
            path: "/list",
            query: { params: JSON.stringify(params) },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: space-between;
  margin: 15px 1%;
  .right {
    width: 83%;
    // height: calc(100vh - 250px);
    .el-form {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .el-form-item {
        margin-left: 10px;
      }
      .footer {
        width: 100%;
        text-align: center;
        margin-top: 50px;
      }
      // .el-input {
      //   width: 400px;
      // }
      .el-date-editor--daterange.el-input__inner{
        width: 400px;
      }
    }
  }
  .el-dialog {
    height: 600px;
  }
}
</style>
<style>
.tip_content {
  width: 400px;
  font-size: 18px;
  line-height: 28px;
}
.home .el-dialog__header {
  background: rgb(0, 183, 255);
  color: #fff;
  font-size: 20px;
}
.home .el-dialog__body {
  height: 600px !important;
  overflow: auto;
}
</style>